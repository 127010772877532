import { _ } from "vue-underscore";

export default {
	data: () => ({
		roles: {
			id: 0,
			name: "",
			description: "",
		},
    loading: false,
		error: "",
		backUrl: "/roles",
		permissionsList: [],
		selected: '',
		showLoader: false,
		opened: [],
		rolesList: []
	}),
	computed: {
		selectAllList() {
			if (this.permissionsList.length > 0) {
				return (
					_.where(this.permissionsList, { isSelected: true }).length ===
					this.permissionsList.length
				);
			}
			return false;
		}
	},
	methods: {
		selectChild(child, flag) {
			child.forEach(element => {
				element["isSelected"] = flag;
				if (element.child && element.child.length > 0) {
					this.selectChild(element.child, flag);
				}
			});
		},
		checkUncheckedList(event, element, parent = null, superParent = null, supersubParent = null) {
			const flag = event.target.checked;
			this.toggle(element.id);

			element["isSelected"] = flag;
			if (element.child && element.child.length > 0) {
				this.selectChild(element.child, flag)
			}
			if (parent) {
				let count = 0;
				if (parent.child && parent.child.length > 0) {
					parent.child.forEach(child => {
						child["isSelected"] ? count++ : '';
					});
					if (!flag) {
						parent["isSelected"] = flag;
					} else {
						if (count == parent.child.length) {
							parent["isSelected"] = true;
						}
					}
				}
			}
			if (superParent) {
				let count = 0;
				if (superParent.child && superParent.child.length > 0) {
					superParent.child.forEach(child => {
						child["isSelected"] ? count++ : '';
					});
					if (!flag) {
						superParent["isSelected"] = flag;
					} else {
						if (count == superParent.child.length) {
							superParent["isSelected"] = true;
						}
					}
				}
			}
			if (supersubParent) {
				let count = 0;
				if (supersubParent.child && supersubParent.child.length > 0) {
					supersubParent.child.forEach(child => {
						child["isSelected"] ? count++ : '';
					});
					if (!flag) {
						supersubParent["isSelected"] = flag;
					} else {
						if (count == supersubParent.child.length) {
							supersubParent["isSelected"] = true;
						}
					}
				}
			}
			this.toggle(element.id);
		},
		resetForm() {
			this.$refs.observer.reset();
			this.roles = {
				id: 0,
				name: "",
				description: "",
			};
		},
		validateRoles() {
			let ids = [];
			this.permissionsList.forEach(element => {
				if (element.isSelected == true) {
					ids.push(element.id);
				}
				element.child.forEach(subelement => {
					if (subelement.isSelected == true) {
						ids.push(subelement.id);
						ids.push(element.id);
					}
					subelement.child.forEach(subchildelement => {
						if (subchildelement.isSelected == true) {
							ids.push(subchildelement.id);
							ids.push(subelement.id);
							ids.push(element.id);
						}
						subchildelement.child.forEach(supsubchildelement => {
							if (supsubchildelement.isSelected == true) {
								ids.push(supsubchildelement.id);
								ids.push(subchildelement.id);
								ids.push(subelement.id);
								ids.push(element.id);
							}
						});
					});
				});
			});
			ids = _.uniq(ids);
			this.roles.id > 0 ? this.update(ids) : this.add(ids);

      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 10000);
		},
		update(ids) {
			let _vm = this;
			this.axios
				.post("/roles/" + this.roles.id, {
					...this.roles,
					ids: ids,
					_method: "PATCH"
				}).then(function (response) {
					_vm.roles = response.data.data;
					_vm.$router.push(_vm.backUrl);
				})
				.catch(function () { });
		},
		add(ids) {
			let _vm = this;
			this.axios
				.post("/roles", { ..._vm.roles, ids: ids })
				.then(function () {
					_vm.$router.push(_vm.backUrl);
				})
				.catch(function () { });
		},
		getQueryString() {
			let queryString = "?search=";
			return queryString;
		},
		getDetail() {
			let _vm = this;
			this.axios
				.get("/roles/" + this.$route.params.id)
				.then(function (response) {
					_vm.roles = response.data.data;
					_vm.getPermissionDetail();
					_vm.getChildRole();
				})
				.catch(function () { });
		},
		getChildRole() {
			const _vm = this
			this.axios
				.get('/roles-details', _vm.rolesList)
				.then(function (response) {
					_vm.rolesList = response.data.data
				})
				.catch(function () { })
		},
		getPermissionDetail() {
			let _vm = this;
			this.axios
				.get("/permissions")
				.then(function (response) {
					response.data.data.forEach(element => {
						if(element.child && element.child.length>0){
							element.child.forEach(subelement => {
								if(subelement.child && subelement.child.length>0){
									subelement.child.forEach(subchild => {
										if (subchild.child && subchild.child.length > 0) {
											subchild.child.forEach(supsubchild => {
												supsubchild['isSelected'] = _.where(_vm.roles.permissions, { id: supsubchild.id }).length > 0
											});
											subelement['isSelected'] = _.where(subelement.child, { isSelected: true }).length === subelement.child.length
										} else {
											subelement['isSelected'] = _.where(_vm.roles.permissions, { id: subelement.id }).length > 0
										}
										subchild['isSelected'] = _.where(_vm.roles.permissions, { id: subchild.id }).length > 0
									});
									subelement['isSelected'] = _.where(subelement.child, { isSelected: true }).length  ===subelement.child.length
								}else{
									subelement['isSelected'] = _.where(_vm.roles.permissions, { id: subelement.id }).length > 0
								}
							});
							element['isSelected'] = _.where(element.child, { isSelected: true }).length  ===element.child.length
						}else{
							element['isSelected'] = _.where(_vm.roles.permissions, { id: element.id }).length > 0
						}


					});
					_vm.permissionsList = response.data.data;
				})
				.catch(function () { });
		},
		toggle(id) {
			const permissionsList = this.opened.indexOf(id);
			if (permissionsList > -1) {
				this.opened.splice(permissionsList)
			} else {
				this.opened.push(id)
			}
		}

	},
	mounted() {
		this.getChildRole();
		if (this.$route.params.id > 0) {
			this.getDetail();
		} else {
			this.getPermissionDetail();
		}


	}
}
